<!--
  PACKAGE_NAME : src/pages/organize/grade
  FILE_NAME : index.vue.vue
  AUTHOR : jhcho
  DATE : 24. 7. 5.
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <Tab title="직위 관리">
        <Grade v-if="tabIndex === 0" />
      </Tab>
      <Tab title="직책 관리">
        <Position v-if="tabIndex === 1" />
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import Tabs from '@/components/common/tabs.vue';
import Tab from '@/components/common/tab.vue';
import Grade from './grade.vue';
import Position from './position.vue';

export default {
  components: {
    Tabs,
    Tab,
    Grade,
    Position
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  methods: {
    tabSelectedIndex(index) {
      this.tabIndex = index;
    },
  },
}
</script>